import { Routes, Route } from "react-router-dom";
import PageSuccess from "../modules/PageSuccess";

import ResetPassword from "../modules/ResetPassword";

const RoutesApp: React.FC = () => {
  //edit
  return (
    <Routes>
      <Route path="/reset-password/:tokenId" element={<ResetPassword />} />
      <Route path="/" element={<PageSuccess />} />
    </Routes>
  );
};

export default RoutesApp;
