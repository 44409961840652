import ResetPassword from "./modules/ResetPassword";
import RoutesApp from "./Routes";
import GlobalStyle from "./shared/styles/GlobalStyle";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  //routes
  return (
    <Router>
      <GlobalStyle />
      <RoutesApp />
    </Router>
  );
}

export default App;
