import React, { useCallback, useEffect, useRef, useState } from "react";
// import IconSvg from "../IconSvg";
import {
  Box,
  ButtonLogin,
  Container,
  Flex,
  FlexButtons,
  Heading,
  Input,
  LoadingContainer,
  LoginBox,
  StyledIcon,
  Text,
} from "./styles";

// import { Container } from './styles';
import logoBeehive from "../../assets/logo.svg";

import { AiFillCheckCircle } from "react-icons/ai";

import passwordIcon from "../../assets/icon-password.svg";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
// import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import api from "../../services/api";
// import { LoadingContainer } from "../../../dash/screen/FeedbackEditScreen/styles";
// import { LoadingSpinner } from "../../../../shared/components/LoadingSpinner";
import MoonLoader from "react-spinners/MoonLoader";

interface Inputs {
  newPassword: string;
  confirmPassword: string;
}

interface ObjectNewPasswordProps {
  token?: string;
  password: string;
  password_confirmation: string;
}

const PageSuccess: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const inputRef = useRef<any>(null);
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const handlePassword = useCallback(() => {}, []);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#000");

  useEffect(() => {
    toast.success("Password successfully updated.");
  }, []);

  return (
    <Container>
      <ToastContainer position="top-center" />
      <LoginBox>
        <img src={logoBeehive} className="login-logo" />
        <StyledIcon />
        <Text>Go back to the app to log in</Text>
      </LoginBox>
    </Container>
  );
};

export default PageSuccess;
