import { createGlobalStyle } from "styled-components";
import { lighten } from "polished";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: "Open Sans", sans-serif;
    :link {
      text-decoration: none !important;
    }
  }

  html {
    font-size: 10px;
  }

  ul, li {
    list-style: none;
  }
  button {
    cursor: pointer;
    border: none;
  }
  body {
    overflow: hidden;
    background: #EBEDEF;
    -webkit-font-smoothing: antialiased;
  } 

  .login-logo {
  width: 220px;

  
  @media (max-width: 600px) {
    width: 190px;
  }
}

.button-excel {

cursor: pointer;
   :hover {
    background-color:  lighten(0.8, #FFC600);
    -webkit-box-shadow: 0px 0px 8px -5px #000000;
    box-shadow: 0px 0px 8px -5px #000000;
    
  }
}


.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2.5px solid #000; 
  border-top: 2.5px solid white; 
  border-radius: 50%;
  animation: spinner 260ms linear infinite;
}



:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided2
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast--error {
   font-size: 1rem;
   width: 360px;
   margin-left: 2rem;
   border-radius: 5px;
   margin: 2rem auto;
}
.Toastify__toast--error::after {
  
   font-size: 1rem;
}
.Toastify__toast--error::before {
 
    font-size: 1rem;
}
.Toastify__toast--success {
    font-size: 1rem;
}
.Toastify__toast--success::before {

    font-size: 1rem;
}
.Toastify__toast--success::after {
 
    font-size: 1rem;
}
.Toastify__toast--warning {
     font-size: 1rem;
}
  

.Toastify__toast-body {
     font-size: 1.5rem;
} 
.Toastify__toast > button>  svg {
    display: none;
}


`;

export default GlobalStyle;
