import styled from "styled-components";
import { GoEye } from "react-icons/go";
import { GoEyeClosed } from "react-icons/go";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebedef;

  @media (max-width: 500px) {
    padding: 0 2rem;
  }

  .input-main-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const LoginBox = styled.form`
  width: 560px;
  height: 490px;
  display: flex;
  padding: 4.2rem;
  background-color: white;
  flex-direction: column;
  border-radius: 5px;

  & .img-test {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 1366px) {
    width: 526px;
    height: 460px;
  }
`;

export const Heading = styled.h1`
  margin-top: 2.7rem;
  font-family: "Open Sans";
  font-size: 5rem;
  font-weight: normal;
  margin-bottom: -0.6rem;

  @media (max-width: 1366px) {
    font-size: 3.8;
    margin-top: 2rem;
    margin-bottom: -0.6rem;
  }

  @media (max-width: 600px) {
    font-size: 2.8rem;
  }
`;

export const Text = styled.p`
  font-family: "Open Sans";
  font-size: 2rem;
  color: #9d9d9d;
  font-weight: normal;
  margin-top: 13px;
  margin-bottom: 0.5rem;

  @media (max-width: 1366px) {
    font-size: 1.6rem;
  }

  @media (max-width: 600px) {
    font-size: 1.6rem;
  }
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  height: 47px;
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  margin-top: 23px;
`;

export const Box = styled.div`
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebedef;
  border-radius: 5px 0px 0px 5px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: none;
  font-size: 1.8rem;
  text-align: left;
  padding-left: 1rem;

  &::placeholder {
    font-size: 1.6rem;
    text-align: left;
  }

  &:focus {
    border: 2px solid #ffc600;
    border-radius: 0px 5px 5px 0;
  }

  @media (max-width: 1366px) {
    &::placeholder {
      font-size: 1.5rem;
      text-align: left;
    }
  }

  @media (max-width: 600px) {
    ::placeholder {
      font-size: 1.4rem;
      transform: translateY(-1px);
    }
  }
`;

export const FlexButtons = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonLogin = styled.button`
  width: 100%;
  height: 47px;
  background: #ffc600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: bold;

  transition: all 120ms linear;
`;

export const ButtonForgotPassword = styled.p`
  font-size: 2rem;
  color: #3250e2;
  font-family: "Arial";
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  padding-right: 3.2rem;
`;

export const StyledIconEye = styled(GoEye)`
  color: black;
  transform: scale(2);
  display: flex;
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;

  @media (max-width: 500px) {
    top: 18px;
  }
`;

export const StyledIconEyeHidden = styled(GoEyeClosed)`
  color: red;
  transform: scale(2);
  display: flex;
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;

  @media (max-width: 500px) {
    top: 18px;
  }
`;
