import React, { useCallback, useRef, useState } from "react";
// import IconSvg from "../IconSvg";
import {
  Box,
  ButtonLogin,
  Container,
  Flex,
  FlexButtons,
  Heading,
  Input,
  LoadingContainer,
  LoginBox,
  StyledIconEye,
  StyledIconEyeHidden,
  Text,
} from "./styles";
import logoBeehive from "../../assets/logo.svg";

import passwordIcon from "../../assets/icon-password.svg";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";

// import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import api from "../../services/api";
// import { LoadingContainer } from "../../../dash/screen/FeedbackEditScreen/styles";
// import { LoadingSpinner } from "../../../../shared/components/LoadingSpinner";
import MoonLoader from "react-spinners/MoonLoader";

interface Inputs {
  newPassword: string;
  confirmPassword: string;
}

interface ObjectNewPasswordProps {
  token?: string;
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const inputRef = useRef<any>(null);
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const handlePassword = useCallback(() => {}, []);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#000");
  const [visible, setVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const ObjectForApi: ObjectNewPasswordProps = {
      token: tokenId,
      password: data.newPassword,
      password_confirmation: data.confirmPassword,
    };

    try {
      setLoading(true);
      if (data.newPassword !== data.confirmPassword) {
        setLoading(false);
        return toast.error("Passwords does not match.");
      }

      if (!data.newPassword || !data.confirmPassword) {
        setLoading(false);
        return toast.error("All fieds are required!");
      }
      setLoading(true);
      const responseNewPassword = await api.post(
        "password/reset",
        ObjectForApi
      );

      toast.success("Successfully. Go back to the app to log in");
      setLoading(false);
      navigate("/");
    } catch (error: any) {
      console.log(error, "error when post new password");
      toast.error("Error! Try again later");
      setLoading(false);
      if (
        error.response?.data?.message ===
        "Incorrect email/password combination."
      ) {
        alert("E-mail ou senha incorreto.");
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <ToastContainer position="top-center" />
      <LoginBox onSubmit={handleSubmit(onSubmit)}>
        <img src={logoBeehive} className="login-logo" />
        <Heading>Reset password</Heading>
        <Text>Create new password</Text>
        <Flex ref={inputRef}>
          <Box>
            <img src={passwordIcon} />
          </Box>
          <div className={"input-main-wrapper"}>
            <Input
              type={!visible ? "password" : "text"}
              placeholder="New password"
              minLength={6}
              {...register("newPassword")}
            />

            {!visible ? (
              <StyledIconEye onClick={() => setVisible(true)} />
            ) : (
              <StyledIconEyeHidden onClick={() => setVisible(false)} />
            )}
          </div>
        </Flex>
        <Flex>
          <Box>
            <img src={passwordIcon} />
          </Box>
          <div className="input-main-wrapper">
            <Input
              type={!visibleConfirm ? "password" : "text"}
              placeholder="Confirm new password"
              minLength={6}
              {...register("confirmPassword")}
            />

            {!visibleConfirm ? (
              <StyledIconEye onClick={() => setVisibleConfirm(true)} />
            ) : (
              <StyledIconEyeHidden onClick={() => setVisibleConfirm(false)} />
            )}
          </div>
        </Flex>
        {/* <Flex>
        <Box>
          <IconSvg image={passwordIcon} width="16px" height="20px" />
        </Box>
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Confirm new password"
        />
      </Flex> */}
        <FlexButtons>
          <ButtonLogin className="button-excel">
            {loading ? (
              <LoadingContainer>
                <MoonLoader
                  color={color}
                  // loading={loading}
                  // css={override}
                  size={25}
                  speedMultiplier={1.2}
                />
              </LoadingContainer>
            ) : (
              "Save"
            )}
          </ButtonLogin>
        </FlexButtons>
      </LoginBox>
    </Container>
  );
};

export default ResetPassword;
